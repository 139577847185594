/* eslint-disable */

import { ApolloProvider, useQuery } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useRouter } from 'next/router';
import * as React from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { Dashboard } from '@fluentsolar/lib-systems/feature-Dashboard';

import { QUERY_GET_LOCAL_ITEMS } from '@fluentsolar/graphql';
import { useApollo } from '@fluentsolar/shared-web/utils';
import '@fluentsolar/shared-web/styles/globals.css';


export default function App({ Component, pageProps, err }) {
  const isDev = process.env.NODE_ENV === 'development';

  const url = isDev
    ? '/api'
    : 'https://systems.fluentsolar.com/api';

  const apolloClient = useApollo(pageProps.initialApolloState, url);

  const localInfo = useQuery(QUERY_GET_LOCAL_ITEMS, { client: apolloClient });
  const router = useRouter();

  const dashboardIndex = router?.asPath?.search('/dashboard');

  const isDashboard = dashboardIndex >= 0;

  const page = router?.asPath?.substr(dashboardIndex);
  // Workaround for https://github.com/vercel/next.js/issues/8592
  return (
    <ApolloProvider
      client={{
        ...apolloClient,
        context: {
          headers: {
            authorization: `Bearer ${localInfo.data.localItems.token}`,
          },
        },
      }}
    >
      <div className="theme-general theme-dark bg-primary text-normal">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {isDashboard ? (
            <Dashboard {...pageProps} page={page}>
              <Component {...pageProps} err={err} />
            </Dashboard>
          ) : (
            <Component {...pageProps} err={err} />
          )}
        </MuiPickersUtilsProvider>
      </div>
    </ApolloProvider>
  );
}
